<template>
  <section class="containerCollections">
    <backToTopButton @scroll-to-top="scrollToTop" :scrollY="scrollY" />
    <section class="containerCollections__info">
      <div class="containerCollections__info--header">
        <span class="title">COLLECTIONS</span>
        <button class="button" @click="toggleCreateCollectionsModal(true)">
          <span class="grey-icon-close"></span>
          <span class="button__label">{{newCollectionLabel}}</span>
        </button>
      </div>
      <div class="containerCollections__info--body">
        <section class="searchContainer">
          <span class="grey-icon-search"></span>
          <input v-model="searchFilter" ref="inputSearch" class="searchContainer__input" type="text" placeholder="Search...">
        </section>
        <section class="collectionsTabs">
          <div class="collectionsTabs__labels">
            <span
              class="collectionsTabs__labels--label"
              :class="{'selected': tabSelected === 'shared'}"
              @click="selectTab('shared')">
              SHARED WITH ME
            </span>
            <span
              class="collectionsTabs__labels--label"
              :class="{'selected': tabSelected === 'mine'}"
              @click="selectTab('mine')">
              MY COLLECTIONS
            </span>
          </div>
          <section class="containerCollectionTab" v-if="collectionsLoaded && persistedVerified">
            <ul class="containerCollectionTab__list" v-if="filteredCollections.length">
              <li class="collectionCard" :class="{'centered': tabSelected === 'mine'}" v-for="collection in filteredCollections" :key="collection">
                <img class="collectionCard__img" :src="collection.thumbnailUrl">
                <span class="collectionCard__title" :class="{'box-vertical': store.state.isMobileResolution}">
                  <a class="collectionCard__title--link" :href="detailLink(collection.id)" :title="collection.name">{{collection.name}}</a>
                </span>
                <div class="collectionCard__author" v-if="tabSelected === 'shared'">
                  <img class="collectionCard__author--img" :src="collection.ownerImage" v-if="!store.state.isMobileResolution">
                  <p class="collectionCard__author--nameDate">
                    <span class="name box-vertical" :title="store.getters.formatAuthorName(collection.userName)">
                      By {{store.getters.formatAuthorName(collection.userName)}}
                    </span>
                    <span class="date box-vertical" :title="store.getters.formatDate(collection.createdDate)">
                      {{store.getters.formatDate(collection.createdDate)}}
                    </span>
                  </p>
                </div>
                <collectionOptions
                  class="collectionCard__actions"
                  :hasPeopleItems="collection.hasPeopleItems"
                  :isPublic="collection.isPublic"
                  :isMine="collection.isMine"
                  :isEmpty="collection.count"
                  :collectionId="collection.id"
                  :name="collection.name"
                  :showMoreResolution="767"
                  :coOwners="collection.totalCoOwners"
                  :isCoOwner="collection.canEdit"
                  @setup-page-again="loadCollections">
                </collectionOptions>
                <p class="collectionCard__counter">
                  {{collection.count}}
                  <span :class="collection.isPublic ? 'icon-grey-public' : 'icon-grey-private'"></span>
                </p>
              </li>
            </ul>
            <section class="containerCollectionTab__noCollections" v-else-if="!searchFilter">
              <img class="containerCollectionTab__noCollections--image" src="@/assets/no-collections.svg" @load="imageLoaded = true"/>
              <p class="containerCollectionTab__noCollections--label" v-if="imageLoaded">{{ getEmptyMessage }}</p>
            </section>
            <section class="containerCollectionTab__noCollections" v-else>
              <img class="containerCollectionTab__noCollections--image" src="@/assets/search2.gif" @load="imageNoResultsLoaded = true"/>
              <p class="containerCollectionTab__noCollections--label" v-if="imageNoResultsLoaded">No results have been found.</p>
            </section>
          </section>
          <skeletonCollections v-else></skeletonCollections>
        </section>
      </div>
      <createCollectionModalGeneric
        v-if="createCollectionModalVisible"
        :userLogged="store.state.dataUserLogin.email"
        @cancel-action="toggleCreateCollectionsModal(false)"
        @close="toggleCreateCollectionsModal(false)"
        @reload-collections="loadCollections"
      ></createCollectionModalGeneric>
    </section>
  </section>
</template>

<script>

import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import skeletonCollections from '@/components/skeletons/skeletonCollections/skeletonCollections'
import collectionOptions from '@/components/collectionOptions/collectionOptions'
import $ from 'jquery'

export default {
  name: 'collections',
  components: {
    skeletonCollections,
    collectionOptions
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const collectionsLoaded = ref(false)
    const tabSelected = ref('shared')
    const searchFilter = ref('')
    const createCollectionModalVisible = ref(false)
    const collections = ref([])
    const inputSearch = ref(null)
    const imageLoaded = ref(false)
    const imageNoResultsLoaded = ref(false)
    const persistedVerified = ref(false)
    const scrollY = ref(0)

    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
        persistedVerified.value = false
      } else {
        persistedVerified.value = true
      }
    }

    /**
     * @description Map collections to show them
     */
    const filteredCollections = computed(() => {
      return collections.value.filter((value) => {
        return value.name.toLowerCase().indexOf(searchFilter.value.toLowerCase().trim()) !== -1
      })
    })

    /**
     * @description Returns link for detail page
     * @param {string} id collection id
     */
    const detailLink = computed(() => (id) => {
      return `${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/detailCollection/collections_${id}`
    })

    /**

     * @description Get empty message depending on the tab selected

     */

    const getEmptyMessage = computed(() => {
      return tabSelected.value === 'shared' ? 'Collections shared with you will appear here' : `Start creating a Collection with the “${newCollectionLabel.value}” button.`
    })

    /**
     * @description Get label of new collection button according screen resolution
     */
    const newCollectionLabel = computed(() => {
      return `New${!store.state.isMobileResolution ? ' Collection' : ''}`
    })

    onMounted(() => {
      window.onpopstate = function () {
        setUpPage()
      }
      setUpPage()
      inputSearch.value.focus()
      setBackToTopButton()
    })

    /**
     * @description gets main container to be able to listen to its scroll
     */
    function setBackToTopButton () {
      let mainSpace
      if (window.innerWidth < 1024) {
        mainSpace = document.querySelector('.topSpaceMobile')
      } else {
        mainSpace = document.querySelector('.leftSpaceDesktop')
      }
      mainSpace.addEventListener('scroll', () => {
        scrollY.value = mainSpace.scrollTop
      })
    }

    /**
     * @description scrolls the page to the top
     */
    function scrollToTop () {
      $('html, #app').animate({ scrollTop: 0 }, 500)
    }

    /**
     * @description Sets tab to show and loads collections
     */
    function setUpPage () {
      tabSelected.value = route.query.s
      loadCollections()
    }

    /**
     * @description Call service to get user's collections
     */
    function loadCollections () {
      const serviceToCall = tabSelected.value === 'shared' ? 'getSharedWithMeCollections' : 'getMineCollections'
      collectionsLoaded.value = false
      store.dispatch({
        type: `collections/${serviceToCall}`
      }).then((response) => {
        collectionsLoaded.value = true
        if (response.length) collections.value = response
        else collections.value = []
        searchFilter.value = ''
      })
    }

    /**
     * @description Show or hide create collection modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Sets the tab to show
     * @param {string} tab tab selected by user
     */
    function selectTab (tab) {
      if (tabSelected.value === tab) return
      store.dispatch({
        type: 'collections/cancelCollectionsRequest'
      })
      searchFilter.value = ''
      tabSelected.value = tab
      store.commit({
        type: 'UPDATE_COLLECTIONS_PAGE_URL',
        tab: tabSelected.value
      })
      loadCollections()
    }

    return {
      store,
      collectionsLoaded,
      tabSelected,
      filteredCollections,
      searchFilter,
      createCollectionModalVisible,
      toggleCreateCollectionsModal,
      selectTab,
      loadCollections,
      detailLink,
      newCollectionLabel,
      inputSearch,
      getEmptyMessage,
      imageLoaded,
      imageNoResultsLoaded,
      persistedVerified,
      scrollToTop,
      scrollY
    }
  }
}
</script>
