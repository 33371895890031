<template>
  <div class="containerSkeletonCollections">
    <div class="containerSkeletonCollections__card" v-for="item in items" :key="item">
      <div class="containerSkeletonCollections__card--left">
        <div class="img"></div>
        <div class="title">
          <div class="title__line"></div>
          <div class="title__line" v-if="store.state.isMobileResolution"></div>
        </div>
      </div>
      <div class="containerSkeletonCollections__card--right">
        <div class="counter"></div>
        <div class="icon"></div>
        <div class="options" v-if="store.state.isMobileResolution"></div>
      </div>
      <div class="containerSkeletonCollections__card--bottom" v-if="store.state.isMobileResolution">
        <div class="name"></div>
        <div class="date"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'skeletonCollections',
  setup (props) {
    const store = useStore()
    const items = ref(11)

    return {
      items,
      store
    }
  }
}
</script>
